// These are custom contact property ids, not actual lists in hubspot.
export enum EmailListEnum {
    // Main lists
    ONNIT_NEWSLETTER = "list_onnit_newsletter",
    ONNIT_SPECIAL_OFFERS = "list_onnit_special_offers",
    ONNIT_ACADEMY = "list_onnit_academy",
    BLACK_SWAN_YOGA = "list_black_swan_yoga",

    // Product trial lists
    TRIAL_NEW_MOOD = "list_trial_new_mood",
    TRIAL_SHROOM_TECH_SPORT = "list_trial_shroom_tech_sport",
    TRIAL_ALPHA_BRAIN = "list_trial_alpha_brain",

    // One-off random lists
    PAGE_KETTLEBELLS = "list_page_kettlebells",
    PAGE_NEW_MOOD_FUNNEL = "list_page_new_mood",
    PAGE_SEMI_ANNUAL_SALE = "list_page_semi_annual_sale",
    PAGE_MARVEL = "list_page_marvel",
    PAGE_AUBREY_MARCUS = "list_page_aubrey_marcus",
    PAGE_COWBOY_CERRONE = "list_page_cowboy_cerrone",
    PAGE_STEEL_MACE = "list_page_steel_mace",
    LIST_ROGAN_KETTLEBELL_DRAWING = "list__joe_rogan_kettlebell_drawing" // Yes the double underscore is correct
}

export enum EmailLeadSourceEnum {
    ONNIT_NEWSLETTER = "onnit:newsletter",
    ROGAN_KETTLEBELL = "rogan_kettlebell_page"
}
